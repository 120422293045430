<template>
    <div style="padding-top: 1rem;">
        <div class="reports_filter px-5 mb-8">
            <v-row>
                <v-col cols="2" class="mr-4">
                    <z-input-date v-model="filter.startsAt" class="mr-1" label="De:" format="pt-BR" type="date"
                        :hideDetails="true" />
                </v-col>
                <v-col cols="2" class="mr-4">
                    <z-input-date v-model="filter.endsAt" class="mr-1" label="Até:" format="pt-BR" type="date"
                        :hideDetails="true" />
                </v-col>
                <!-- <v-col cols="2" class="mr-4">
                    <z-select v-model="filter.journeyId" :hideDetails="true" label="Filtre por jornada" itemValue="id"
                        :rules="[(v) => !!v || 'Campo obrigatório']" :items="journeys" itemText="name" />
                </v-col> -->
                <v-col cols="2" class="mr-4">
                    <z-btn text="Filtrar" style="margin-right: 8px; margin-bottom: 5px" primary @click="filterReports()" />
                </v-col>
            </v-row>
        </div>
        <div v-if="!isLoading" class="wrapper__card mx-5">
            <!-- <div class="wrapper__card__header">

            </div> -->
            <div class="wrapper__card__body">
                <v-row justify="space-around">
                    <v-col cols="4">
                        <v-card elevation="2" shaped class="mx-3 py-2">
                            <v-row justify="center">
                                <div class="icon_card_report">
                                    <v-icon class="ml-3 mt-3" color="white">$send</v-icon>
                                </div>
                                <div class="ml-5">
                                    <v-row class="mb-1">
                                        <span style="font-size: 0.85rem; font-weight: 700;">TOTAL DE ENVIOS</span>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <span style="color: rgb(246, 189, 2);">{{ totalSent }} (100%)</span>
                                    </v-row>

                                </div>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card elevation="2" shaped class="mx-3 py-2">
                            <v-row justify="center">
                                <div class="icon_card_report" style="background-color: rgba(255, 143, 40, 1)">
                                    <v-icon class="ml-3 mt-3" color="white">$drafts</v-icon>
                                </div>
                                <div class="ml-5">
                                    <v-row class="mb-1">
                                        <span style="font-size: 0.85rem; font-weight: 700;">TOTAL DE ABERTURAS</span>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <span style="color: rgba(255, 143, 40, 1);">{{ totalOpens }} ({{ totalSent !== 0 ?
                                            (totalOpens / totalSent * 100).toFixed(2) : 0 }}%)</span>
                                    </v-row>

                                </div>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card elevation="2" shaped class="mx-3 py-2">
                            <v-row justify="center">
                                <div class="icon_card_report" style="background-color: rgba(73, 203, 140, 1)">
                                    <v-icon class="ml-3 mt-3" color="white">$arrow_back</v-icon>
                                </div>
                                <div class="ml-5">
                                    <v-row class="mb-1">
                                        <span style="font-size: 0.85rem; font-weight: 700;">TOTAL DE CLIQUES</span>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <span style="color: rgba(73, 203, 140, 1);">{{ totalClicks }} ({{
                                            totalSent !== 0 ? (totalClicks / totalSent * 100).toFixed(2) : 0 }}%)</span>
                                    </v-row>

                                </div>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="3">
                        <v-card elevation="2" shaped class="mx-3 py-2">
                            <v-row justify="center">
                                <div class="icon_card_report" style="background-color: rgba(111, 42, 130, 1)">
                                    <v-icon class="ml-3 mt-3" color="white">$cached</v-icon>
                                </div>
                                <div class="ml-5">
                                    <v-row class="mb-1">
                                        <span style="font-size: 0.85rem; font-weight: 700;">ROI</span>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <span style="color: rgba(111, 42, 130, 1);"> 2432 (100%)</span>
                                    </v-row>

                                </div>
                            </v-row>
                        </v-card>
                    </v-col> -->
                </v-row>
                <v-row style="height: 50vh !important;">
                    <v-col cols="12">
                        <v-row class="ml-2 mt-2">
                            <span style="font-size: .8rem; font-weight: 600;">SEQUÊNCIA DE FUNIL</span>
                        </v-row>
                        <v-row class="mt-10" style="margin-left: 50px;">
                            <TreeComponent 
                                v-if="treeTest != null" 
                                :isReportRender="true"
                                :nodes="treeTest" 
                                :key="keyComponent"
                                :database-config-id-prop="databaseConfigId" 
                                @node_click="nodeClick" 
                            />
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div v-else>
            <div class="wrapper__card">
                <v-row justify="center">

                    <z-loading />
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import moment from "moment";
import { computed } from "vue";
import TreeComponent from "./components/fluxogram/ExampleList.vue";
// import LineChart from '../Reports/components/Line.vue'
// import DoughnutChart from '../Reports/components/Doughnut.vue'
export default {
    components: {
        // LineChart,
        // DoughnutChart,
        TreeComponent
    },
    data() {
        return {
            http: new ApiClient(),
            totalSent: null,
            totalOpens: null,
            totalClicks: null,
            totalByChannel: null,
            openRateByDateRange: null,
            clickRateByDateRange: null,
            keyComponent: 0,
            clicksData: null,
            opensData: null,
            journeyFilterSelected: null,
            isLoading: false,
            headers: [
                { text: "Campanha", value: "name", sortable: true },
                { text: "Engajamento", value: "engagement", sortable: true },
                { text: "Data do envio", value: "sentDate", sortable: true },
                { text: "Hora do envio", value: "sentHour" },
                { text: "Relatório", value: "report" },
            ],
            journeys: [],
            paginatedItems: [],
            filtered: [],
            recent: [],
            data: {
                orientation: "hor",
                centerX: 1024,
                centerY: 140,
                scale: 1,
                nodes: [],
                links: [],
            },
            filter: {
                startsAt: null,
                endsAt: null,
                journeyId: null
            },
            treeTest: {},
            tree: {},
            journey: {},
            currentJourney: {},
            audiencesList: [],
            nos: [],
            templatesSMSList: [],
            templatesEmailList: [],
            databaseConfigId: null
        };
    },
    async created() {
        this.filter.journeyId = this.$route.params.id;
        await this.callAllMethodsFromReport()
    },
    provide() {
        return {
        databaseConfigId: computed(() => this.databaseConfigId),
        };
    },
    methods: {
        nodeClick(event) {
            this.clickedNodeId = event;

        },
        async getCurrentJourney() {
            try {
                this.isTreeLoading = true;
                const response = await this.http.get(`journey/findOne/${this.$route.params.id}`);
                this.currentJourney = response;
                // console.log(response);
                this.journey = response.journey;
                const TREE = this.buildTree(response.steps);
                this.treeTest = TREE;
                this.tree = TREE;
                this.keyComponent++;
                this.databaseConfigId = this.journey.audienceId;
            } catch (error) {
                console.log(error)
                this.$toast.error("Erro ao buscar informações");
                this.isTreeLoading = false;
            }

            return;
        },
        buildTree(arr) {
            const nodeMap = {};
            let tree = {};

            const BLOCK_TYPES = {
                selectAudience: {
                    label: "Selecionar Audiência",
                    isDataSelected: false,
                    formatType: "circle",
                    hasNextNode: true,
                    color: "#dcdcdc",
                    icon: "mdi-calendar",
                    lastNode: null,
                },
                final: {
                    label: "Final",
                    isDataSelected: false,
                    formatType: "circle",
                    hasNextNode: false,
                    color: "#dcdcdc",
                    icon: "$exit_to_app_rounded",
                    nextNode: null,
                    lastNode: null,
                    fromClick: "next",
                },
                sendEmail: {
                    label: "Selecionar\ne-mail",
                    formatType: "square",
                    hasNextNode: true,
                    isDataSelected: false,
                    icon: "mdi-email",
                    color: "#0b99ff",
                    lastNode: null,
                    nextNode: null,
                    fromClick: "next",
                },
                sendSMS: {
                    label: "Enviar SMS",
                    formatType: "square",
                    hasNextNode: true,
                    icon: "mdi-message-processing",
                    color: "#0b99ff",
                    isDataSelected: false,
                    lastNode: null,
                    nextNode: null,
                    fromClick: "next",
                },
                await: {
                    label: "Selecionar período",
                    type: "await",
                    icon: " $timer_rounded",
                    formatType: "circle",
                    hasNextNode: true,
                    isDataSelected: false,
                    color: "#dcdcdc",
                    lastNode: null,
                    nextNode: null,
                    fromClick: "next",
                },
                selectAudienceFilter: {
                    label: "Selecionar audiência filtrada",
                    type: "selectAudienceFilter",
                    formatType: "diamond",
                    hasNextNode: true,
                    isDataSelected: false,
                    color: "#dcdcdc",
                    lastNode: null,
                    YesNext: null,
                    NoNext: null,
                    fromClick: "next",
                },
                selectTestABFilter: {
                    label: "Teste A/B",
                    type: "selectTestABFilter",
                    formatType: "diamond",
                    hasNextNode: true,
                    color: "#dcdcdc",
                    isDataSelected: false,
                    lastNode: null,
                    YesNext: null,
                    NoNext: null,
                    fromClick: "next",
                },
            };

            arr.forEach((obj) => (nodeMap[obj.id] = obj));

            arr.forEach((obj) => {
                const PARENT_ID = obj.lastStepId;
                const DATA = obj;

                // MERGE DE OBJETOS, PARA O OBJETO TER TODAS A PROPRIEDADES
                const NODE = Object.assign(DATA, BLOCK_TYPES[obj.type]);

                // VERIFICAR CADA TIPO DE INFORMAÇÃO PARA VERIFICAR SE JÁ TEM DADOS SELECIONADOS OU NÃO
                if (NODE.type === "selectAudience")
                    if (this.currentJourney.journey.audienceId) {
                        NODE.isDataSelected = true;
                        const AUDIENCE = this.audiencesList.find(
                            (d) => d.id === this.currentJourney.journey.audienceId
                        );
                        if (AUDIENCE) NODE.label = AUDIENCE.name;
                    }

                if (NODE.type === "sendSMS" || NODE.type === "sendEmail")
                    if (NODE.templateId) {
                        NODE.isDataSelected = true;
                        const TEMPLATE = this.templatesSMSList.find((d) => d.id == NODE.templateId);
                        if (TEMPLATE) NODE.label = TEMPLATE.templateName;
                    }

                if (NODE.type === "await")
                    if (NODE.wait && NODE.waitValue) {
                        NODE.isDataSelected = true;
                        NODE.label = `${NODE.waitValue} ${NODE.wait}`;
                    }

                // SE NÃO TIVER O PARENTE ID, ELE É O PRIMEIRO NÓ (RAIZ)
                if (!PARENT_ID) {
                    tree = NODE;
                    this.pushNewNode(this.data.nodes.length - 1, NODE, true);
                } else {
                    const PARENT_NODE = nodeMap[PARENT_ID];

                    // SE O YESNEXTID FOR NULO, O PROXIMO NÓ OBRIGATORIAMENTE É DO NEXTNODE
                    if (!PARENT_NODE.yesNextStepId) {
                        PARENT_NODE.nextNode = {};
                        PARENT_NODE.nextNode = NODE;
                    }

                    if (PARENT_NODE.yesNextStepId) {
                        PARENT_NODE.YesNext = {};
                        PARENT_NODE.YesNext = nodeMap[PARENT_NODE.yesNextStepId];
                        PARENT_NODE.YesNext.fromClick = "yes";

                        if (PARENT_NODE.nextStepId) {
                            PARENT_NODE.nextNode = {};
                            PARENT_NODE.nextNode = nodeMap[PARENT_NODE.nextStepId];
                        }
                    }
                    this.pushNewNode(this.data.nodes.length - 1, NODE, true);
                }
            });

            return tree;
        },
        pushNewNode(index, obj, replace) {
            if (replace) this.data.nodes.splice(index, 0, obj);
            else this.data.nodes.push(obj);
        },
        async callAllMethodsFromReport() {
            this.isLoading = true
            await Promise.all([
                this.getTotalSent(),
                this.getTotalOpens(),
                this.getTotalClicks(),
                this.getTotalByChannel(),
                this.getOpenRateByDateRange(),
                this.getClickRateByDateRange(),
                this.getCurrentJourney()
            ])
            setTimeout(() => {
                this.isLoading = false
            }, 3000);
        },
        async filterReports() {
            await this.callAllMethodsFromReport()
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        formatHour(date) {
            return moment(date).format("HH:mm");
        },
        async getTotalSent() {
            this.http
                .post("reports/getTotalSent", this.filter)
                .then((resp) => {
                    this.totalSent = resp.total;
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        async getTotalOpens() {
            this.http
                .post("reports/getTotalOpens", this.filter)
                .then((resp) => {
                    this.totalOpens = resp.total;
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        async getTotalClicks() {
            this.http
                .post("reports/getTotalClicks", this.filter)
                .then((resp) => {
                    this.totalClicks = resp.total;
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        async getTotalByChannel() {
            this.http
                .post("reports/getTotalByChannel", this.filter)
                .then((resp) => {
                    this.totalByChannel = resp;
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        async getOpenRateByDateRange() {
            this.http
                .post("reports/getOpenRateByDateRange", this.filter)
                .then((resp) => {
                    this.openRateByDateRange = resp
                    this.opensData = (this.treatDataRange(resp));
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        async getClickRateByDateRange() {
            this.http
                .post("reports/getClickRateByDateRange", this.filter)
                .then((resp) => {
                    this.clickRateByDateRange = resp
                    this.clicksData = (this.treatDataRange(resp));
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        async getJourneysReportsByCompanyId() {
            this.http
                .post("reports/getJourneysReportsByCompanyId", this.filter)
                .then((resp) => {
                    this.journeys = resp

                })
                .catch((err) => {
                    console.log(err)
                });
        },
        treatDataRange(arr) {
            let temp = []
            for (let index = 0; index < 12; index++) {
                const i = arr.findIndex((item) => parseInt(item.month) === index + 1);
                if (i === -1) {
                    temp.push(0)
                }
                else {
                    temp.push(parseInt(arr[i].count))
                }
            }
            return temp;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.icon_card_report {
    height: 50px;
    width: 50px;
    background-color: rgb(246, 189, 2);
    border-radius: 30px;
}

.wrapper {
    color: $z-black-1;
    padding: $z-s-1;
    height: calc(100vh - 48px);

    &__card {
        padding: $z-s-1;
        background: #ffffff;
        border-radius: 15px;
        height: 100%;
        overflow-y: hidden;

        // overflow-y: auto;
        // @include trackScrollBar;
        &__header {
            display: flex;
            align-items: center;
            gap: $z-s-1;
            margin-bottom: $z-s-2;
            flex-wrap: wrap;

            .left {
                flex: 1 1 auto;

                span {
                    font-weight: 700;
                    font-size: 1.1em;
                    line-height: 15px;
                }
            }

            .right {
                flex: 2 1 600px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;
                gap: $z-s-1 0.5rem;

                &>div {
                    &:first-child {
                        width: 40%;
                    }
                }

                @media (max-width: 808px) {
                    justify-content: flex-start;

                    &>div {
                        flex: 1 1 350px;
                    }
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: $z-s-1;
            height: 90%;

            // overflow-y: auto;
            // @include trackScrollBar;
            .table-wrapper {
                height: 450px !important;
                max-height: 500px;
                overflow-y: auto;
                @include trackScrollBar;

                .no-data-table {
                    text-align: center;
                }

                @media (min-width: 1400px) {
                    height: auto !important;
                    max-height: 650px;
                }
            }

            // NOVAS TABELAS ADICIONADAS
            @media (min-width: 750px) {
                ::v-deep .v-data-table {
                    table {
                        th {
                            &:not(:first-child) {
                                text-align: center !important;
                            }

                            &:last-child {
                                text-align: right !important;
                            }
                        }

                        td {
                            &:not(:first-child) {
                                text-align: center !important;

                                &>div,
                                span {
                                    margin: 0 auto !important;
                                }
                            }

                            &:last-child {
                                text-align: right !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .badge {
        padding: 0.03rem 0.25rem;
        border-radius: 50px;
        font-size: 0.75rem;
        font-weight: 500;
        transition: 0.2s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        max-width: 58%;

        @media (max-width: 650px) {
            max-width: 100%;
        }

        &:hover {
            // background: rgb(236, 236, 236);
        }
    }
}

.list-options {
    background: #ffffff;

    &__item {
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.2s ease;

        span {
            font-weight: 500;
        }

        &:hover {
            background: #e2e2e2;
        }
    }
}

.delete-confirmation {
    padding: 1rem;

    &__title {
        text-align: center;
        font-weight: 500;
    }

    &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }
}

.no-data {
    .wrapper__card {
        height: 50vh !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .loader {
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #3498db;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite;
            /* Safari */
            animation: spin 2s linear infinite;
        }

        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        span {
            font-weight: 700;
            font-size: 1.1em;
            line-height: 15px;
        }
    }
}
</style>
